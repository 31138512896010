export const Levels = [
  {
    id: 10,
    v: {
      en: "Beginner",
      es: "Principiante",
      cat: "Principiant",
    },
  },
  {
    id: 20,
    v: {
      en: "Advanced Beginner",
      es: "Principiantes Avanzados",
      cat: "Principiants avançats",
    },
  },
  {
    id: 25,
    v: { en: "Elementary", es: "Elemental", cat: "Elemental" },
  },
  {
    id: 30,
    v: { en: "Intermediate", es: "Medio", cat: "Intermedi" },
  },
  {
    id: 40,
    v: {
      en: "Intermediate/ Advanced",
      es: "Medio/ Avanzado",
      cat: "Mig/avançat",
    },
  },
  {
    id: 50,
    v: { en: "Advanced", es: "Avanzado", cat: "Avançat" },
  },
];
