import {
  JSX,
  createContext,
  createMemo,
  createSignal,
  onMount,
} from "solid-js";
import * as i18n from "@solid-primitives/i18n";
import { dictionaries } from "./i18n/dict";
import { GlobalState, Leveli18, LocaleType } from "./types";
// import "./preflight.scss";
import "./App.css";

import Aulao from "./Aulao";
import { getLevels } from "./data/directus";

type LocaleProviderProps = {
  initial: LocaleType;
  children: JSX.Element;
};

// Create a global state variable
export const [globalState, setGlobalState] = createSignal<GlobalState>({
  locale: "es",
});

export const LocaleContext = createContext<{
  locale: LocaleType;
  setLocale: (l: LocaleType) => void;
  t: (key: string) => string;
  tLevel: (key: number, long?: boolean) => string;
}>();

export function LocaleProvider(props: LocaleProviderProps) {
  const [locale, setLocale] = createSignal<LocaleType>(
    props.initial as LocaleType
  );
  const [levels, setLevels] = createSignal<Leveli18>();

  const dict = createMemo(
    () => i18n.flatten(dictionaries(levels())[locale() || "es"])
    // !!levels
    // ? i18n.flatten(dictionaries(levels)[locale()])
    // : i18n.flatten(dictionaries()[locale()])
  );

  onMount(async () => {
    setLevels(await getLevels());
    // console.log(
    //   "dddict",
    //   levels(),
    //   i18n.flatten(dictionaries(levels())[locale()]),
    //   dict(),
    //   prefixLevelsByLocale(locale(), levels(), "ll")
    // );
  });

  const t = (key: any) => i18n.translator(dict)(key);
  const tLevel = (key: any, long?: boolean) =>
    t(`level${long ? ".long" : ""}.${key}`);

  const value = {
    locale: locale(),
    setLocale,
    t,
    tLevel,
  };

  return (
    <LocaleContext.Provider value={value}>
      {props.children}
    </LocaleContext.Provider>
  );
}

type AppProperties = {
  lang?: string;
};

function App({ lang }: AppProperties) {
  const [searchParams] = createSignal(new URLSearchParams(location.search));

  const locale: LocaleType =
    (["ca", "cat", "en", "es"].includes(lang || "") && (lang as LocaleType)) ||
    ((["cat", "en", "es"].includes(searchParams().get("l") || "") &&
      searchParams().get("l")) as LocaleType);

  // cat is not the right locale type, but ca is. For no just sanitize
  const localeC = (locale as String).replace("ca", "cat");
  onMount(() => {
    setGlobalState({ locale: localeC as LocaleType });
  });

  return (
    <div id="aulao" class="aulao">
      <LocaleProvider initial={localeC as LocaleType}>
        <Aulao embeded={!!lang} />
      </LocaleProvider>
    </div>
  );
}

export default App;
