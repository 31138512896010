import {
  For,
  createEffect,
  createMemo,
  createSignal,
  onMount,
  useContext,
} from "solid-js";
import {
  Category,
  Class,
  CourseCategories,
  LocaleType,
  Teacher,
  WeekDay,
} from "~/types";
import ClassesTable from "./components/ClassesTable";
import { LocaleContext } from "./App";

import { store } from "./data/store";
import { Collapseable } from "./components/Collapsable";
import ComboBox from "./components/ComboBox";
import { funnel } from "solid-heroicons/solid";
import { Icon } from "solid-heroicons";

// import { Icon } from "solid-heroicons";
// import { xCircle } from "solid-heroicons/solid";

type AulaoProperties = {
  embeded?: boolean;
};

export default function Aulao({ embeded = false }: AulaoProperties) {
  // const { fetch, teachers, classes, categories, levels } = useStore();
  // (state) => state

  // const { t } = useContext(LocaleContext);

  // const locale: LocaleTypes = useContext(LangContext) as LocaleTypes;

  // const data = useRouteData<typeof routeData>();

  // console.log("route data", data()?.classes, data()?.teachers);

  // const [query, setQuery] = createSignal<string>();
  // const [searchParams, setSearchParams] = useSearchParams();
  // const [classes, setClasses] = createSignal<Class[] | undefined>(
  //   ddd()?.classes
  // );
  // const [teachers, setTeachers] = createSignal<Teacher[] | undefined>(
  //   ddd()?.teachers
  // );

  // const [selectedCategories, setSelectedCategories] = createSignal<string[]>(
  //   []
  // );
  // const [_searchParams, setSearchParams] = useSearchParams();

  const [withFilter, setWithFilter] = createSignal<Boolean>(embeded);
  const [selectedCategory, setSelectedCategory] = createSignal<string>();
  const [categoryOpen, setCategoryOpen] = createSignal<string>();

  const [selectedTeacher, setSelectedTeacher] = createSignal<string>();
  const [selectedDays, setSelectedDays] = createSignal<string>();

  const [filterTapProgram, _setfilterTapProgram] = createSignal<boolean>(false);
  // const [selectedTimeRange, setSelectedTimeRange] = createSignal("");

  const [selectionStore, setSelectionStore] = createSignal<
    Record<string, string>
  >({});

  // const selected = searchParams.c?.split("|");
  // const initialData: Class[] = untrack(async () => classes);

  const [filteredData, setFilteredData] = createSignal<CourseCategories>({});
  const [filteredCategories, setFilteredCategories] = createSignal<string[]>(
    []
  );
  const [filteredTeachers, setFilteredTeachers] = createSignal<Set<string>>();
  const [filteredDays, setFilteredDays] = createSignal<Set<string>>();

  const memoizedFilteredData = createMemo(() => filteredData());
  const memoizedFilteredCategories = createMemo(() => filteredCategories());
  // const nonSelected = createMemo(
  //   () => !selectedCategory() && !selectedTeacher() && !selectedDays()
  // );

  const contextValue = useContext(LocaleContext);

  if (!contextValue) {
    // Handle the case where the context value is undefined or null
    return null;
  }

  createEffect(() => {
    // const parents = filteredData().filter((c) => !c.filter((c) => !c.parent);
    const categories = Object.keys(filteredData());
    // console.log("..... cateogries", categories, categories[0]);
    // setSelectedCategory(categories.length == 1 ? categories[0] : undefined);
    setCategoryOpen(categories.length == 1 ? categories[0] : undefined);
  }, [selectedCategory]);

  const { locale, t } = contextValue;
  console.log("lang", t, locale);
  // const [data, setData] = createSignal<{
  //   classes: Class[];
  //   teachers: Teacher[];
  //   categories: Category[];
  //   levels: Leveli18;
  //   weekdays: WeekDay[];
  // }>({
  //   classes: [],
  //   teachers: [],
  //   categories: [],
  //   levels: [],
  //   weekdays: [],
  // });

  onMount(async () => {
    // const [classes, teachers, categories, levels] = await Promise.all([
    //   getClasses() as Promise<Class[]>,
    //   getTeachers() as Promise<Teacher[]>,
    //   getCategories(locale) as Promise<Category[]>,
    //   getLevels() as Promise<Leveli18>,
    // ]);

    await store.fetch(locale || "es");

    // const weekdays: WeekDay[] = WeekDays["en"].map((w, index) => ({
    //   id: index + 1 + "",
    //   label: w,
    // }));

    // const weekdays: WeekDay[] = [
    //   ...classes.reduce((acc: Set<number>, row) => {
    //     if (!!row.weekdays && typeof row.weekdays == "object") {
    //       row.weekdays.forEach((weekday) => {
    //         acc.add(weekday);
    //       });
    //     }
    //     return acc;
    //   }, new Set<string>()),
    // ]
    //   .sort()
    //   .map((dayIndex) => ({
    //     id: dayIndex + "", // string cast
    //     label: WeekDays["en"][dayIndex - 1],
    //   }));

    // setData({
    //   classes,
    //   teachers,
    //   categories,
    //   levels,
    //   weekdays,
    // });
  });

  const findCategory = (id: string) =>
    store.categories.find((c: any) => c.id == id);

  // data()?.categories.find((c: any) => c.id == id);

  createEffect(() => {
    const cat = selectedCategory();
    const teacher = selectedTeacher();
    // const days = selectedDays();
    const uniqueCategories = new Set<string>();
    const teachers = Array<string>();

    const classes = store.classes.reduce(
      (acc: CourseCategories, row: Class) => {
        // data()?.classes.reduce((acc: CourseCategories, row: Class) => {
        // if category matches and others are not selected
        // if (filterTapProgram()) {
        // }
        const category = row["category"];
        if (
          !filterTapProgram() &&
          cat &&
          row.category !== cat &&
          cat != findCategory(row.category)?.parent
        ) {
          return acc;
        }

        row["teachers"].forEach((t) => teachers.push(t));
        // if teacher match and other not selected

        if (!filterTapProgram() && teacher && !row.teachers.includes(teacher)) {
          return acc;
        }

        // if days match and others not selected
        if (
          !filterTapProgram() &&
          selectedDays() &&
          !row.weekdays.map((wd) => "" + wd).includes(selectedDays() || "")
        ) {
          return acc;
        }

        if (filterTapProgram() && (!row.formation || row.formation == "")) {
          return acc;
        }

        const rowWithSelected = {
          ...row,
          // selected: selected?.includes(row.id) || false,
        };

        if (acc[category]) {
          acc[category].push(rowWithSelected);
        } else {
          acc[category] = [rowWithSelected];
        }

        // add parent first
        const parentCategoryId = findCategory(category)?.parent;
        parentCategoryId && uniqueCategories.add(parentCategoryId);

        uniqueCategories.add(category);

        return acc;
      },
      {} as CourseCategories
    );

    if (cat) {
      setFilteredTeachers(new Set(teachers));
      console.info("cat seleted", teachers, filteredTeachers());
    } else {
      setFilteredTeachers(undefined);
      setFilteredDays(undefined);
    }
    console.info("classs", classes);
    setFilteredCategories(Array.from(uniqueCategories));
    console.log("cats...", filteredCategories, uniqueCategories);
    setFilteredData(classes || {});
  });

  function handleAccordion(e: Event, catId: string) {
    e.preventDefault();

    setCategoryOpen(categoryOpen() == catId ? undefined : catId);
  }
  function changeLocale(s: LocaleType) {
    console.log("lang selected", s);
    // setSearchParams({ l: s });
    const url = new URL(location.href);
    url.searchParams.set("l", s);
    // history.pushState({}, "", url);
    document.location.search = `l=${s}`;
  }

  return (
    // <Router>
    <main
      class="mx-auto flex max-w-7xl items-center justify-between md:p-6 lg:px-8"
      aria-label="Global"
    >
      <div data-theme="mytheme" class="prose mx-auto w-full">
        {!embeded && (
          <div class="flex flex-row gap-2">
            <button
              class={`btn btn-xs ml-2 `}
              onclick={() => setWithFilter(!withFilter())}
            >
              {withFilter() ? (
                <Icon path={funnel} class="w-4 h-4 text-purple-600" />
              ) : (
                <Icon path={funnel} class={"w-4 h-4 text-purple-300"} />
              )}
            </button>
            <div class="pl-12 flex flex-row gap-1">
              <span class="text-xs">Botones provisionales:</span>
              <button
                class="btn btn-xs ml-2"
                onclick={() => changeLocale("es")}
              >
                es
              </button>
              <button class="btn btn-xs" onclick={() => changeLocale("cat")}>
                cat
              </button>
              <button class="btn btn-xs" onclick={() => changeLocale("en")}>
                en
              </button>
            </div>
          </div>
        )}

        {withFilter() && (
          <div class="flex flex-col gap-2 form-control mx-auto w-11/12 sm:w-auto">
            {/* <p class="text-center text-xs">
              {nonSelected() ? t("filterEmptyText") : " "}
            </p> */}

            <ComboBox
              id="CategoryCombo"
              label={t("category")}
              options={() =>
                store.categories.filter((c: Category) => !c.parent)
              }
              selected={selectedCategory}
              setSelected={setSelectedCategory}
              placeholder={t("comboPlaceHolderCategory")}
            />
            <ComboBox
              id="TeacherCombo"
              label={t("teacher")}
              options={() =>
                store.teachers
                  .filter(
                    (t: Teacher) =>
                      !filteredTeachers() || filteredTeachers()?.has(t.id)
                  )
                  .map((t: Teacher) => ({
                    id: t.id,
                    label: t.label,
                  }))
              }
              selected={selectedTeacher}
              setSelected={setSelectedTeacher}
              placeholder={t("comboPlaceHolderTeacher")}
            />
            <ComboBox
              id="WeekdayCombo"
              label={t("Days")}
              options={() =>
                store
                  .weekdays(locale)
                  .filter(
                    (t: WeekDay) => !filteredDays() || filteredDays()?.has(t.id)
                  )
                  .map((t: WeekDay) => ({
                    id: t.id,
                    label: t.label,
                  }))
              }
              selected={selectedDays}
              setSelected={setSelectedDays}
              placeholder={t("comboPlaceHolderDays")}
            />
            {/* <div class="flex flex-row-reverse">
            <label class="text-right label cursor-pointer">
              <span class="label-text px-2 text-xs">
                {t("filterTapProgram")}{" "}
              </span>
              <input
                type="checkbox"
                class="toggle level-formacion h-6 toggle-xs mr-6 sm:mr-8"
                checked={filterTapProgram()}
                onchange={(e) => setfilterTapProgram(e.target.checked)}
              />
            </label>
          </div> */}
          </div>
        )}

        <div>
          {memoizedFilteredCategories().length < 1 ? (
            <p class="text-center">{t("NoClassesFound")}</p>
          ) : (
            <For
              each={store.categories.filter((c: Category) =>
                memoizedFilteredCategories().includes(c.id)
              )}
              // each={memoizedFilteredCategories()}
            >
              {(c) => {
                // const c = findCategory(categoryId);
                if (!c) return;
                return (
                  <>
                    {!c.parent && (
                      <h2 id={c?.id} class="px-2">
                        {c.label}
                      </h2>
                    )}

                    {memoizedFilteredData()[c.id] && (
                      <Collapseable
                        enabled={memoizedFilteredCategories().length > 1}
                        category={c}
                        categoryOpen={categoryOpen}
                        handleAccordion={handleAccordion}
                      >
                        <ClassesTable
                          category={c.id}
                          // subCategory={category}
                          // levels={data().levels.}
                          data={() => memoizedFilteredData()[c.id]}
                          setSelectionStore={setSelectionStore}
                          selectionStore={selectionStore}
                          highlightTerms={
                            selectedTeacher()
                              ? [selectedTeacher() as string]
                              : undefined
                          }
                        />
                      </Collapseable>
                    )}
                  </>
                );
              }}
            </For>
          )}
        </div>
      </div>
      <span class="h-20">.</span>
    </main>
    // </Router>
  );
}
