import { createDirectus, rest, readItems } from "@directus/sdk";
import {
  Category,
  Class,
  ClassTeacher,
  DbCategory,
  DbLevel,
  DbTeacher,
  Leveli18,
  LocaleType,
  Teacher,
  Translations,
  ClassDB,
  Setting,
} from "~/types";

interface Schema {
  categories: DbCategory[];
  levels: DbLevel[];
  teachers: DbTeacher[];
  classes: Class[];
  classes_teachers: ClassTeacher[];
  comments: Comment[];
  settings: Setting[];
}

const DIRECTUS_BASE_URL = "https://dir.kivia.de";
// "http://localhost:8900/dirka";
// "http://localhost:8990";
// "https://dir.kivia.de";

const client = createDirectus<Schema>(DIRECTUS_BASE_URL).with(rest());

export const getLocaleValue = (
  label: Translations,
  locale: LocaleType,
  fallback?: string
): string => label[locale] || label["es"] || fallback || "";

export const getSettings = async () => {
  const settings = await client.request(readItems("settings"));
  return settings;
};

export const getCategories = async (
  locale: LocaleType
): Promise<Category[]> => {
  const categories = await client.request(readItems("categories"));
  return categories
    .sort((a, b) => a.sort - b.sort)
    .map((c) => ({
      id: c.id,
      parent: c.parent,
      label: getLocaleValue(c.label as Translations, locale || "es", c.id),
    }));
};

// async (locale?: LocaleType): Promise<Level[]> => {
export const getLevels = async (): Promise<Leveli18> => {
  const levels = await client.request(readItems("levels"));
  return levels
    .sort((a, b) => a.id - b.id)
    .map((l) => ({ id: l.id, v: l.value }));
  // .map((l) => ({
  //   id: l.id,
  //   label:
  //     getLocaleValue(
  //       l.value as Translations,
  //       locale || "es",
  //       l.id.toString()
  //     ) || l.internal,
  // }));
};

export const getTeachers = async (): Promise<Teacher[]> => {
  const teachers = await client.request(readItems("teachers"));
  return teachers.map((t) => ({
    id: t.id,
    label: t.name,
  }));
};

export const getClasses: () => Promise<Class[]> = async () => {
  const classes = await client.request<ClassDB[]>(
    readItems("classes", {
      fields: [
        "*",
        {
          categroies: ["*"],
          comment: [
            {
              translations: ["languages_code", "text"],
            },
          ],
          teachers: [{ teachers_id: ["*"] }],
        },
      ],
      limit: 200,
      // fields: ["*", { teachers: [{ teachers_id: ["*"] }] }],
    })
  );
  const mappedClasses = classes.map((c) => ({
    id: String(c.id),
    title: c.title || undefined,
    starttime: c.starttime,
    duration: c.duration,
    startdate: c.startdate || undefined,
    enddate: c.enddate || undefined,
    level: c.level,
    comment: c.comment?.translations.reduce((acc, t) => {
      acc[t.languages_code] = t.text;
      return acc;
    }, {} as Translations),
    formation: c.formation,
    weekdays: c.weekdays
      .map((wn) => (typeof wn == "string" ? parseInt(wn) : wn))
      .sort(),
    // .map((wn) => (typeof wn == "number" ? WeekDays["en"][wn - 1] : wn)),
    category: c.category,
    ocupacion: c.ocupacion || undefined,
    teachers: c.teachers.map((t) => (t as any).teachers_id.id),
  }));
  // console.log(mappedClasses);
  return mappedClasses;
};

// console.log(await getClasses());
// console.log(await getCategories());
