import { Accessor, JSX } from "solid-js";
import { Category } from "~/types";

interface CollapseableProps {
  enabled: boolean;
  category: Category;
  categoryOpen: Accessor<string | undefined>;
  handleAccordion: (e: Event, c: string) => void;
  children: JSX.Element;
}
export function Collapseable({
  enabled,
  category,
  categoryOpen,
  handleAccordion,
  children,
}: CollapseableProps) {
  return !enabled ? (
    children
  ) : (
    <div
      class={`p-0 m-0 collapse-${
        categoryOpen() == category.id ? "open" : "close"
      }
                   collapse collapse-arrow my-1 `}
    >
      <div
        class={`collapse-title min-h-8 p-1 cursor-pointer text-xl font-medium 
        ${
          categoryOpen() == category.id ? "bg-crimson text-white" : "bg-gray-50"
        }`}
        onClick={(e) => handleAccordion(e, category.id)}
      >
        <span class="p-2 ">{category.label}</span>
      </div>
      <div class={`collapse-content m-0 p-0`}>{children}</div>
    </div>
  );
}
