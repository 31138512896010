import { createSignal, For, Accessor, onCleanup, createEffect } from "solid-js";
import { check, xCircle } from "solid-heroicons/solid";
import { Icon } from "solid-heroicons";
import { Option } from "~/types";

interface Props {
  id?: string;
  options: () => Option[];
  selected: Accessor<string | undefined>;
  setSelected: (options: string | undefined) => void;
  placeholder: string;
  label: string;
}

export function ComboBox({ id, options, selected, setSelected, label }: Props) {
  //   let inputRef: { contains: (arg0: Node) => any };
  let inputRef!: HTMLInputElement;

  const [searchTerm, setSearchTerm] = createSignal("");
  const [dropdownVisible, setDropdownVisible] = createSignal(false);
  const [dropdownHovered, setDropdownHovered] = createSignal(false);
  const [selectedIndex, setSelectedIndex] = createSignal(-1);

  const optionsMemo = options;
  const [filteredOptions, setFilteredOptions] = createSignal<Option[]>(
    optionsMemo()
  );

  createEffect(() =>
    setFilteredOptions(
      optionsMemo().filter((option) =>
        option.label.toLowerCase().includes(searchTerm().toLowerCase())
      )
    )
  );

  const isSelected: (value?: string) => boolean = (value) =>
    !!value && (selected()?.includes(value) || false);

  const handleKeyDown = (e: any) => {
    if (dropdownVisible() == false) setDropdownVisible(true);
    if (
      e.key === "ArrowDown" &&
      selectedIndex() < filteredOptions().length - 1
    ) {
      e.preventDefault();
      setSelectedIndex(selectedIndex() + 1);
    } else if (e.key === "ArrowUp" && selectedIndex() > 0) {
      e.preventDefault();
      setSelectedIndex(selectedIndex() - 1);
    } else if (e.key === "Enter" && selectedIndex() >= 0) {
      setSelected(filteredOptions()[selectedIndex()].label);
      setDropdownVisible(false);
    }
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (inputRef && !inputRef.contains(e.target as Node)) {
      setDropdownVisible(false);
    }
  };

  createEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  onCleanup(() => {
    setSelectedIndex(-1);
    setDropdownVisible(false);
  });

  return (
    <div class="w-full">
      <div
        class="flex flex-row gap-2"
        onMouseEnter={() => setDropdownHovered(true)}
        onMouseLeave={() => setDropdownHovered(false)}
      >
        <label
          tabindex="0"
          class="w-3/12 text-gray-400  sm:flex hidden"
          html-for={id}
        >
          {label}
        </label>
        <div class="w-9/12 sm:7/12 ">
          <div class="w-full p-0 m-0">
            <input
              class={
                "m-0 p-0 pl-8 " +
                (typeof selected() != "undefined"
                  ? " font-bold "
                  : " sm:placeholder-transparent placeholder-gray-400")
              }
              id={id}
              ref={inputRef}
              tabindex="0"
              type="text"
              // placeholder={label}
              placeholder={
                optionsMemo().find((o) => o.id == selected())?.label || label
              }
              onInput={(e) => setSearchTerm(e.currentTarget.value)}
              onClick={(e) => {
                // Clear the search term when the input is clicked
                setSearchTerm("");
                e.currentTarget.value = "";
              }}
              onFocus={() => {
                setDropdownVisible(true);

                // Focus on the dropdown element
                // const dropdownElement = document.querySelector(
                //   ".dropdown"
                // ) as HTMLElement;

                // console.log("dropdown", dropdownElement);
                // if (dropdownElement) {
                //   dropdownElement.focus();
                // }
              }}
              onBlur={() => {
                // setDropdownVisible(false);
                if (!dropdownHovered()) {
                  setDropdownVisible(false);
                }
              }}
              onKeyDown={handleKeyDown}
            />
          </div>
          {dropdownVisible() && filteredOptions().length > 0 ? (
            <ul
              tabindex="0"
              class={
                "menu sm:w-80 w-3/4 m-0 p-0 mt-2 -ml-1 rounded-box bg-secondary absolute z-50  " +
                ""
                // dropdownVisible()
                // ? "block bg-slate-500"
                // : "hidden bg-red-500"
              }
              // class="menu bg-base-200 w-56 p-0 [&_li>*]:rounded-none"
              // style={{ display: dropdownVisible() ? "block" : "none" }}
            >
              <For each={filteredOptions()}>
                {(option, index) => {
                  // const isHovered = createMemo(() => index() === selectedIndex());
                  return (
                    <li
                      // class={
                      //   // "flex flex-row  pl-0 ml-0 " +
                      //   // (isHovered() ? "bg-red-300" : "") +
                      //   isSelected(option.value)
                      //     ? " bg-slate-400 text-white rounded-md "
                      //     : ""1
                      // }
                      class="my-0"
                      onMouseEnter={() => setSelectedIndex(index)}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelected(option.id);
                        setDropdownVisible(false);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setSelected(option.id);
                          setDropdownVisible(false);
                        }
                      }}
                      tabIndex={0}
                      role="option"
                      aria-selected={isSelected(option.id)}
                      aria-setsize={filteredOptions().length}
                      aria-posinset={index() + 1}
                    >
                      <div
                        class={
                          // "flex flex-row  pl-0 ml-0 " +
                          // (isHovered() ? "bg-red-300" : "") +
                          "py-1" +
                          (isSelected(option.id)
                            ? " bg-primary active text-white font-bold rounded-md "
                            : "")
                        }
                      >
                        {isSelected(option.id) ? (
                          <Icon path={check} class="w-4 h-4 text-white-400" />
                        ) : (
                          <span class="pl-4" />
                        )}
                        <span class="">{option.label}</span>
                      </div>
                    </li>
                  );
                }}
              </For>
            </ul>
          ) : (
            <></>
          )}
        </div>
        <div class="3/12 pl-8">
          {selected() ? (
            // <div
            //   class=" inline-block align-middle bg-red-400"
            //   onClick={() => setSelected(undefined)}
            // >
            <Icon
              onClick={() => {
                setSelected(undefined);
                setDropdownVisible(false);
              }}
              path={xCircle}
              class="pt-2 w-8 h-8"
              // px-8 pt-2 w-8 h-8   text-slate-500"
            />
          ) : (
            <span class="pl-8"></span>
          )}
        </div>
      </div>
    </div>
  );
}

export default ComboBox;
