/* @refresh reload */
import { render } from "solid-js/web";

import App from "./App";
// import Sheet from "./Sheet";

const htmlElement = document.querySelector("html");
const lang = htmlElement?.getAttribute("lang") || "es";

setTimeout(() => {
  const root = document.getElementById("aulao-horarios");
  if (!!root) {
    console.log("Rendering aulao");
    render(() => <App lang={lang.substring(0, 2)} />, root!);
  } else {
    console.log("aulao-horarios element not found.");
  }
}, 400);

// const sheetContainer = document.getElementById("aulao-sheet");
// if (!!sheetContainer) {
//   render(() => <Sheet lang={lang.substring(0, 2)} />, sheetContainer!);
// }
