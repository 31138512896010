import { Levels } from "./levels";
import { Leveli18, LocaleType, ShortLongi18 } from "~/types";

export function isShortLongi18(obj: any): obj is ShortLongi18 {
  return "short" in obj;
}
const weekdayType = "short";

const weekDayFn = (locale: string) => (day: number) =>
  weekdayType == "short" ? WeekDays[locale][day] : WeekDaysLong[locale][day];

export const prefixLevelsByLocale = (
  locale: LocaleType,
  dict: Leveli18 | undefined,
  prefix: string
): Record<string, string> => {
  return !dict
    ? {}
    : dict.reduce<Record<string, string>>((acc, level) => {
        try {
          if (isShortLongi18(level.v)) {
            acc[`${prefix}.${level.id}`] = "" + level.v.short[locale];
            acc[`${prefix}.long.${level.id}`] = "" + level.v.long[locale];
          } else {
            acc[`${prefix}.${level.id}`] =
              level.v[locale] || level.id.toString();
            acc[`${prefix}.long.${level.id}`] =
              level.v[locale] || level.id.toString();
          }
        } catch (error) {
          // console.error("Error, prefixingLevels", error, level);
        }
        return acc;
      }, {});
};

export const dictionaries = (levels?: Leveli18) => ({
  en: {
    title: "Dance Classes",
    filterTapProgram: "Tap program",
    filterEmptyText: "Please use the filter menu to show fewer classes.",
    noClassesFound: "No classes for selection.",
    time: "Time",
    teacher: "Teacher",
    level: "Level",
    Days: "Days",
    category: "Category",
    comboPlaceHolderCategory: "Select a category",
    comboPlaceHolderTeacher: "Filter by teacher",
    comboPlaceHolderDays: "Filter by days",

    weekday: weekDayFn("en"),
    ...prefixLevelsByLocale("en", levels || Levels, "level"),
  },
  es: {
    title: "Clases de Baile",
    filterTapProgram: "Formació professional de claqué",
    filterEmptyText: "Utilice el menú de filtro para mostrar menos clases.",
    noClassesFound: "No hay clases para la selección.",
    time: "Hora",
    teacher: "Profesor/a",
    level: "Nivel",
    Days: "Días",
    category: "Disciplinas",
    comboPlaceHolderCategory: "Seleccione una categoría",
    comboPlaceHolderTeacher: "Filtrar por profesor/a",
    comboPlaceHolderDays: "Filtrar por día",

    weekday: weekDayFn("es"),
    ...prefixLevelsByLocale("es", levels || Levels, "level"),
  },
  cat: {
    title: "Classes de Ball",
    filterTapProgram: "formació professional de claqué",
    filterEmptyText: "Utilitzeu el menú de filtre per mostrar menys classes.",
    noClassesFound: "No hi ha classes per a la selecció.",
    time: "Hora",
    teacher: "Professor/a",
    level: "Nivell",
    Days: "Dies",
    category: "Disciplines",
    comboPlaceHolderCategory: "Seleccioneu una disciplines",
    comboPlaceHolderTeacher: "Filtra per profesor/a",
    comboPlaceHolderDays: "Filtra per dies",

    weekday: weekDayFn("cat "),
    ...prefixLevelsByLocale("cat", levels || Levels, "level"),
  },
});

interface WeekDays {
  [key: string]: string[];
}
const WeekDaysLong: WeekDays = {
  en: [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ],
  es: [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
  ],
  cat: [
    "Dilluns",
    "Dimarts",
    "Dimecres",
    "Dijous",
    "Divendres",
    "Dissabte",
    "Diumenge",
  ],
};
export const WeekDays: WeekDays = {
  en: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  es: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
  cat: ["Dg", "Dl", "Dt", "Dc", "Dj", "Dv", "Ds"],
};
